// Bootstrap
import "bootstrap/js/dist/util"
// import "bootstrap/js/dist/alert"
// import "bootstrap/js/dist/button"
// import "bootstrap/js/dist/carousel"
// import "bootstrap/js/dist/collapse"
import "bootstrap/js/dist/dropdown"
// import "bootstrap/js/dist/index"
import "bootstrap/js/dist/modal"
// import "bootstrap/js/dist/popover"
// import "bootstrap/js/dist/scrollspy"
// import "bootstrap/js/dist/tab"
// import "bootstrap/js/dist/toast"
// import "bootstrap/js/dist/tooltip"

import "./base/scss/base.scss"

import "jquery-viewport-checker"

// Lightbox for Bootstrap
import "ekko-lightbox/dist/ekko-lightbox"
import "ekko-lightbox/dist/ekko-lightbox.css"

$(document).on("click", '[data-toggle="lightbox"]', function (event) {
  event.preventDefault()
  $(this).ekkoLightbox()
})

import "./base/plain_template/script"
